.Home {
  text-align: center;
  background-color: #080808;
  display: flex;
  flex-direction: column;
  font-family: 'Barlow Condensed';
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}


.Home-navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.Home-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
  /* z-index: 5; */
  box-shadow: 0px 10px 100px 75px rgba(0, 0, 0, 0.6);
}

.Header-video-pc {
  width: 100%;
  margin-top: 2.3vw;
  margin-bottom: 10vw;
}

.Header-video {
  width: 100%;
  margin-top: 8.5vw;
  margin-bottom: 19vw;
}


.Home-body {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.Body-page1 {
  width: 100%;
  /* height: 100vh; */
  /* padding-bottom: 50px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/public/background/home-bd1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
}

.Page1 {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Page1-pc {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Body-page1-top-pc {
  width: 65vw;
  height: 39vw;
  margin-top: -10%;
  z-index: 5;
  position: relative;
  overflow: hidden;
}

.Body-page1-top-pc img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  /* max-height: 100%; */
}

.Body-page1-top {
  width: 100%;
  height: 69vw;
  margin-top: -10%;
  z-index: 5;
  position: relative;
  overflow: hidden;
}

.Body-page1-top img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  /* max-height: 100%; */
}

.Body-page1-mid-pc {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Body-page1-mid {
  width: 90%;
  margin-top: 11vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bottom-icon-pc {
  width: 2vw;
  height: 19vw;
  display: flex;
}

.bottom-icon {
  width: 32px;
  height: 189px;
  display: flex;
}

.bottom-fonts-pc {
  width: 80%;
  margin-left: 7%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.bottom-fonts {
  height: 100%;
  margin-left: 7%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.fonts-top-font-pc {
  width: 18.5vw;
  height: 3vw;
}


.fonts-top-font {
  width: 34vw;
  height: 5.5vw;
}

.fonts-mid-pc {
  display: flex;
  align-items: center;
  text-align: justify;
  font-weight: 600;
  font-size: 3.75vw;
  margin-top: 1.4vw;
}

.home-fonts-mid-mdash-pc {
  width: 6.25vw;
  border: 2px solid #707070;
}

.home-fonts-mid-mdash {
  width: 6.4vw;
  border: 1px solid #707070;
}

.fonts-mid {
  display: flex;
  align-items: center;
  text-align: justify;
  text-align: justify;
  font-weight: 600;
  font-size: 6.4vw;
  margin-top: 2.1vw;
}

.fonts-bottom-pc {
  text-align: left;
  font-weight: 600;
  font-size: 2.5vw;
  margin-top: 1.3vw;
  color: #707070;
  letter-spacing: 1.28px;
}

.fonts-bottom {
  text-align: left;
  font-weight: 600;
  font-size: 5.3vw;
  margin-top: 5vw;
  color: #707070;
}



.Body-page1-bottom-pc {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
  margin-bottom: 7.5vw;
}


.learn-more-pc {
  width: 13vw;
  height: 3vw;
  font-size: 1.4vw;
  font-weight: 600;
  background-color: #2C2C2C;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.learn-more-pc:hover {
  transform: translateY(-1px);
  background-color: #080808;
}






.Body-page1-bottom {
  width: 80%;
  margin-left: 7%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
  margin-bottom: 50vw;
}


.learn-more {
  width: 35vw;
  height: 9vw;
  font-size: 3.7vw;
  font-weight: 600;
  background-color: #2C2C2C;
  display: flex;
  justify-content: center;
  align-items: center;
}

.learn-more:hover {
  transform: translateY(-1px);
  background-color: #080808;
}


.Body-page2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom, #3F3F3F, #000000, #080808);
}

.Page2-pc {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.Body-page2-left-pc {
  display: flex;
  width: 31vw;
  height: 35vw;
  position: relative;
  overflow: hidden;
}

.Body-page2-left-pc img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  /* max-height: 100%; */
}

.Body-page2-mid-pc {
  display: flex;
  height: 35vw;
  justify-content: flex-start;
}

.Body-page2-right-pc {
  color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
}

.Body-page2-right-in-pc {
  color: #fff;
  display: flex;
  width: 39vw;
  height: 19vw;
  flex-direction: column;
}

.page2-right-font1-pc {
  display: flex;
  height: 2vw;
  font-size: 2.3vw;
  font-weight: 600;
  align-items: center;
  color: #FFF;
}

.page2-right-font1-pc img {
  width: 12vw;
  height: 2vw;
}

.page2-right-font2-pc {
  /* height: 12vw; */
  font-size: 1.4vw;
  margin-top: 1.4vw;
  font-weight: 500;
  text-align: justify;
  color: #707070;
}

.page2-right-font3-pc {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1vw;
  margin-top: 1vw;
  width: 100%;
}

.page2-right-font3-in-pc {
  cursor: pointer;
  display: flex;
  width: 12vw;
  height: 1.68vw;
  background-image: url("/public/icon/discordTo.svg");
  background-size: cover;
}

.page2-right-font3-in-pc:hover {
  background-image: url("/public/icon/discordTo2.svg");
}


/* .page2-right-font3-img1-pc:hover,
.page2-right-font3-img2-pc:hover,
.page2-right-font3-font-pc:hover {
  transform: translateY(-5px);
} */

.page2-right-font3-img1-pc {
  width: 0.7vw;
  height: 0.7vw;
  display: flex;
}

.page2-right-font3-img2-pc {
  width: 1.6vw;
  height: 1.6vw;
  display: flex;
}

.page2-right-font3-font-pc {
  /* width: 10vw; */
  font-size: 1.25vw;
  color: #C3C3C3;
}


.Page2 {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Body-page2-left {
  width: 59vw;
  height: 77.4vw;
  display: flex;
  position: relative;
  overflow: hidden;
  margin-top: -42vw;
}

.Body-page2-left img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* max-width: 100%; */
  max-height: 100%;
}


.Body-page2-right {
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 9vw;
}

.page2-right-font1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50vw;
  height: 13vw;
  font-size: 6.4vw;
  font-weight: 600;
  color: #FFF;
  margin-top: 3vw;
}

.page2-right-font1 img {
  width: 35.7vw;
  height: 5vw;
}

.page2-right-font2 {
  width: 100%;
  font-size: 3.2vw;
  font-weight: 500;
  text-align: justify;
  color: #707070;
}

.page2-right-font3 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2vw;
  margin-top: 2vw;
  width: 100%;
}

.page2-right-font3-in {
  display: flex;
  width: 50%;
  height: 5.87vw;
  background-image: url("/public/icon/discordTo.svg");
  background-size: cover;
}

/* .page2-right-font3-img1:hover,
.page2-right-font3-img2:hover,
.page2-right-font3-font:hover {
  transform: translateY(-5px);
} */

.page2-right-font3-img1 {
  width: 2vw;
  height: 2vw;
  display: flex;
}

.page2-right-font3-img2 {
  width: 5.8vw;
  height: 5.8vw;
  display: flex;
}

.page2-right-font3-font {
  /* width: 10vw; */
  font-size: 4.2vw;
  color: #C3C3C3;
}

.Body-page3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/public/background/home-bd2.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
}

.Page3-pc {
  display: flex;
  width: 80%;
  margin-top: 10vw;
  margin-bottom: 8vw;
  align-items: center;
  justify-content: space-between;
}

.Body-page3-left-pc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Body-page3-right-pc {
  text-align: left;
}

.page3-right-title-pc {
  width: 36vw;
  font-size: 5vw;
  color: #080808;
  font-weight: 600;
}

.page3-right-title2-pc {
  color: #707070;
  font-size: 2.5vw;
  font-weight: 500;
}

.page3-right-context-pc {
  width: 38vw;
  color: #707070;
  font-size: 1.4vw;
  margin-top: 2vw;
  text-align: justify;
}


.page3-right-button-pc {
  display: flex;
  width: 100%;
  height: 2.9vw;
  margin-top: 2.8vw;
  justify-content: flex-end;
}

.shopNow-pc {
  cursor: pointer;
  width: 13.5vw;
  font-size: 1.4vw;
  font-weight: 600;
  text-align: center;
  background-color: #2C2C2C;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}


.shopNow-pc:hover {
  transform: translateY(-1px);
  background-color: #080808;
}



.Page3 {
  display: flex;
  width: 90%;
  margin-top: 19vw;
  margin-bottom: 12vw;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Body-page3-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Body-page3-right {
  text-align: justify;
  margin-top: 6vw;
}

.page3-right-title {
  width: 42.4vw;
  font-size: 8.5vw;
  color: #080808;
  font-weight: 600;
}

.page3-right-title2 {
  font-size: 4.2vw;
  color: #707070;
  font-weight: 500;
}

.page3-right-context {
  width: 100%;
  font-size: 3.2vw;
  margin-top: 8.2vw;
  color: #707070;
  text-align: justify;
}


.page3-right-button {
  width: 100%;
  height: 9vw;
  margin-top: 5.8vw;
  display: flex;
  justify-content: flex-end;
}

.shopNow {
  width: 35vw;
  font-size: 3.7vw;
  font-weight: 600;
  text-align: center;
  background-color: #2C2C2C;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}


.shopNow:hover {
  transform: translateY(-1px);
  background-color: #080808;
}

.Body-page4-swiper {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #080808;
}


.Body-page5 {
  width: 100%;
  background-color: #080808;
  display: flex;
  align-items: center;
  justify-content: center;
}


.Page5-pc {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.Body-page5-top-pc {
  width: 100%;
  margin-top: 6.7vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page5-right-title-pc {
  width: 100%;
  font-size: 2.5vw;
  color: #fff;
  text-align: center;
  font-weight: 500;
}

.page5-right-buttons-pc {
  display: flex;
  margin-top: 7vw;
  height: 3vw;
  gap: 1vw;
  justify-content: center;
  align-items: flex-end;
}



.QRcode {
  display: flex;
  width: 3vw;
  /* height: 100%; */
  justify-content: flex-end;
  align-items: flex-end;
  /* overflow: hidden; */
}

.download-app {
  display: flex;
  height: 3vw;
  justify-content: center;
  align-items: center;
  color: #080808;
  font-weight: 600;
  background-color: #fff;
  cursor: pointer;
}

.download-app:hover {
  /* background-color: #080808; */

  transform: translateY(-1px);
}

@media screen and (max-width: 2560px) {

  .QRcode {
    width: 3vw;
  }

  .download-app {
    width: 18.5vw;
    height: 3vw;
    font-size: 1.4vw;
  }
}

@media screen and (max-width: 480px) {

  .QRcode {
    width: 9vw;
    height: 9vw;
  }

  .download-app {
    width: 40.5vw;
    height: 9vw;
    font-size: 4.2vw;
  }
}




.real-QR {
  z-index: 99;
  width: 0;
  height: 0;
  animation: expand-animation 0.5s forwards;
}

@keyframes expand-animation {
  from {
    width: 0;
    height: 0;
  }

  to {
    width: 300%;
    height: 300%;
  }
}

.Body-page5-bottom-pc {
  width: 100%;
  height: 16.6vw;
  margin-top: 5vw;
  margin-bottom: 2vw;
  position: relative;
  overflow: hidden;
}

.Body-page5-bottom-pc img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  /* max-height: 100%; */
}


.Page5 {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.Body-page5-top {
  width: 90%;
  margin-top: 15vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page5-right-title {
  width: 100%;
  font-size: 5vw;
  color: #fff;
  text-align: justify;
  font-weight: 500;
}


.page5-right-buttons {
  display: flex;
  width: 100%;
  margin-top: 20vw;
  height: 9vw;
  gap: 4.5vw;
  justify-content: flex-end;
  align-items: flex-end;
}

.Body-page5-bottom {
  width: 100%;
  height: 33vw;
  margin-top: 20vw;
  position: relative;
  overflow: hidden;
}

.Body-page5-bottom img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  /* max-height: 100%; */
}

.Body-page6 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/public/background/home-bd3.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
}

.Page6-pc {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Page6 {
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Body-page6-top-pc {
  margin-top: 11vw;
  font-size: 5vw;
  color: #080808;
  font-weight: 600;
}

.Body-page6-mid-pc {
  text-align: center;
  color: #707070;
  font-size: 1.4vw;
  margin-top: 3vw;
}

.Body-page6-mid2-pc {
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 2vw;
  margin-top: 4.3vw;
  margin-bottom: 10vw;
}

.theThirdButton {
  color: #000;
  font-family: 'Barlow';
  display: flex;
  border-radius: 4px;
  background: rgba(195, 195, 195, 0.40);
  backdrop-filter: blur(7.5px);
  cursor: pointer;
}

.theThirdButton:hover {
  background: rgba(155, 155, 155, 0.40)
}

.group-top {
  display: flex;
  align-items: center;
}

.group-top-font {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}


.group-top-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-bottom {
  font-weight: 600;
  color: #707070;
}


@media screen and (max-width: 2560px) {

  .theThirdButton {
    width: 19vw;
    height: 12vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .group-top {
    width: 100%;
    gap: 1vw;
    justify-content: center;
  }

  .group-top-font {
    font-size: 1.8vw;
  }


  .group-top-img {
    width: 3vw;
  }

  .group-bottom {
    margin-top: 1.3vw;
    font-size: 1.4vw;
    text-align: center;
  }

}

@media screen and (max-width: 480px) {

  .theThirdButton {
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .group-top {
    width: 30%;
    gap: 3.2vw;
    justify-content: flex-start;
  }

  .group-top-font {
    font-size: 3.7vw;
  }


  .group-top-img {
    width: 6vw;
  }

  .group-bottom {
    width: 30%;
    font-size: 3.2vw;
    text-align: right;
  }
}


.Body-page6-top {
  margin-top: 16vw;
  font-size: 8.5vw;
  color: #080808;
  font-weight: 600;
}

.Body-page6-mid {
  text-align: center;
  color: #707070;
  font-size: 3.7vw;
  margin-top: 8vw;
}

.Body-page6-mid2 {
  display: flex;
  width: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 11vw;
  gap: 0.8vw;
  margin-bottom: 12.8vw;
}







.Body-page7 {
  width: 100%;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}


.Page7-pc {
  display: flex;
  width: 70%;
  margin-top: 2vw;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.Body-page7-top-pc {
  font-size: 3.75vw;
  text-align: start;
  font-weight: 400;
}

.Body-page7-mid-pc {
  width: 49vw;
  height: 4.5vw;
  margin-top: 2vw;
  margin-bottom: 5.6vw;
  font-size: 1.4vw;
  text-align: start;
  font-weight: 500;
  color: #707070;
}

.Body-page7-mid-pc a {
  color: #707070;
  text-decoration: none;
  border-bottom: 2px solid;
}


.Page7 {
  display: flex;
  width: 90%;
  margin-top: 2vw;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.Body-page7-top {
  font-size: 6.4vw;
  text-align: start;
  font-weight: 400;
}

.Body-page7-mid {
  width: 100%;
  height: 15vw;
  margin-top: 5.6vw;
  margin-bottom: 10vw;
  font-size: 3.2vw;
  text-align: start;
  font-weight: 500;
  color: #707070;
}

.Body-page7-mid a {
  color: #707070;
  text-decoration: none;
  border-bottom: 1px solid;
}



.Home-footer {
  width: 100%;
  /* height: 420px; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
  color: #fff;
}

/* input {
  touch-action: manipulation;
} */