.carousel-all {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.carousel-images {
    position: relative;
    overflow: hidden;
}

.carousel-images img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
}

.button-groups {
    width: 43px;
}


@media screen and (max-width: 2560px) {
    .carousel-images {
        width: 32vw;
        height: 40vw;
    }

    .button-groups {
        height: 40vw;
    }
}

@media screen and (max-width: 480px) {

    .carousel-images {
        width: 71vw;
        height: 88.75vw;
    }

    .button-groups {
        height: 88.75vw;
    }
}





/* @media (min-width: 2560px) {
    .carousel-images {
        width: 744px;
        height: 927px;
    }

    .button-groups {
        height: 615px;
    }

}

@media (max-width: 2559px) and (min-width: 1920px) {
    .carousel-images {
        width: 617px;
        height: 769px;
    }

    .button-groups {
        height: 769px;
    }

}

@media (max-width: 1919px) and (min-width: 1201px) {
    .carousel-images {
        width: 493px;
        height: 615px;
    }

    .button-groups {
        height: 615px;
    }

}

@media (max-width: 1200px) and (min-width: 801px) {
    .carousel-images {
        width: 617.25px;
        height: 769.12px;
    }

    .button-groups {
        height: 769.12px;
    }

    .carousel-all {
        margin-top: 216px;
    }
}

@media (max-width: 800px) and (min-width: 413px) {
    .carousel-images {
        width: 516.472px;
        height: 615.294px;
    }

    .button-groups {
        height: 615px;
    }

    .carousel-all {
        margin-top: 30px;
    }
}

@media (max-width: 414px) and (min-width: 376px) {
    .carousel-images {
        width: 303.624px;
        height: 361.719px;
    }

    .button-groups {
        height: 361.719px;
    }

    .carousel-all {
        margin-top: 72px;
    }
}

@media (max-width: 375px) {
    .carousel-images {
        width: 267.386px;
        height: 318.548px;
    }

    .button-groups {
        height: 318.548px;
    }

    .carousel-all {
        margin-top: 70px;
    }
} */


.button-part {
    width: 26px;
    height: 6px;
    margin-bottom: 12px;
    background-color: #c3c3c3;
    transition: width 0.3s, background-color 0.3s;
}

.button-part:hover {
    width: 36px;
    background-color: #2c2c2c;
}

.active {
    width: 36px;
    background-color: #2c2c2c;
}