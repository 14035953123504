.logo-nv2 {
    cursor: pointer;
}



.navbar2 {
    width: 100%;
    background-color: #080808;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


@media screen and (max-width: 2560px) {

    .navbar2 {
        height: 2.3vw;
    }


    .logo-nv2 {
        width: 5.6vw;
        margin-left: 1.6vw;
    }

}

@media screen and (max-width: 480px) {
    .navbar2 {
        height: 8.5vw;
    }

    .logo-nv2 {
        width: 20.8vw;
        margin-left: 5.3vw;
    }
}