.footer1 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-image: url("/public/background/bd4.jpg");
    background-size: cover;
}

.footer1 a {
    color: #C0C0C0;
    text-decoration: none;
}

.footer-in-pc {
    display: flex;
    width: 80%;
    height: 23.4vw;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Barlow';
}

.footer-in {
    display: flex;
    width: 90%;
    height: 56vw;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Barlow';
}

.footer-left-pc {
    display: flex;
    margin-top: 2.4vw;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}



.footer-left {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid #7D7C7C;
}

.footer-right-pc {
    display: flex;
    margin-bottom: 3vw;
    font-size: 0.92vw;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    color: #C3C3C3;
    font-family: 'Barlow';
}

.footer-right-pc a {
    font-size: 0.62vw;
    color: #7d7c7c;
    font-family: 'Barlow';
}


.footer-right {
    display: flex;
    margin-top: 2.7vw;
    margin-bottom: 5.3vw;
    font-size: 2.7vw;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #C3C3C3;
    font-family: 'Barlow';
}

.footer-right a {
    font-size: 1.8vw;
    color: #7D7C7C;
    font-family: 'Barlow';
}

.footer-left-top-pc {
    cursor: pointer;
    width: 9.4vw;
    height: 1.5vw;
}

.footer-left-top {
    width: 25.6vw;
    height: 4.27vw;
    margin-bottom: 2vw;
    margin-top: 10.4vw;
}

.footer-left-mid-pc {
    display: flex;
    margin-top: 2.3vw;
    gap: 4.7vw;
}

.footer-left-mid {
    display: flex;
    margin-top: 10.7vw;
    gap: 10vw;
}

.text-pc {
    text-align: left;
}

.text {
    text-align: left;
}

.text-title-pc {
    font-size: 1.25vw;
    text-align: left;
    color: #C3C3C3;
    font-family: 'Barlow';
    font-weight: 700;
}


.text-title {
    font-size: 2.7vw;
    color: #C3C3C3;
    font-family: 'Barlow';
    font-weight: 700;
}

.text-font-pc {
    cursor: pointer;
    font-size: 1.25vw;
    margin-top: 1.9vw;
    color: #C3C3C3;
    font-family: 'Barlow';
}

.text-font {
    font-size: 2.7vw;
    margin-top: 4vw;
    color: #C3C3C3;
    font-family: 'Barlow';
}



/* 
@media (min-width: 1921px) {
    .ayayi-brand {
        height: 20px;
        margin-top: 90px;
        font-size: 20px;

    }

    .hongkong {
        font-size: 20px;
        height: 20px;
        margin-top: 15px;
    }

    .icons {
        width: 192px;
        height: 46px;
        margin-top: 25px;
        gap: 26px;
    }

    .icons img {
        width: 46px;
        height: 46px;
    }

    .mobile-app {
        font-size: 20px;
        margin-bottom: 38px;
    }

    .google-apple {
        width: 353px;
        height: 178px;
    }

    .google-apple img {
        width: 220px;
        height: 65px;
    }

    .footer-right {
        width: 422px;
        height: 438px;
    }

    .footer-left-bottom {
        width: 412.444px;
        height: 34.286px;
        margin-top: 168px;
        font-size: 24px;
    }

    .text-title {
        margin-bottom: 49px;
        font-size: 32px;
    }

    .text-font {
        margin-bottom: 43px;
        font-size: 32px;
    }

    .text1 {
        width: 237px;
        height: 180px;
    }

    .text2 {
        width: 205px;
        height: 107px;
        margin-left: 122px;
    }

    .text3 {
        width: 188px;
        height: 107px;
        margin-left: 86px;
    }

    .text4 {
        width: 248px;
        height: 180px;
        margin-left: 104px;
    }

    .footer-left {
        width: 1106px;
        margin-left: 46px;
    }

    .footer-left-top {
        width: 237px;
        height: 40px;
    }

    .footer-left-mid {
        width: 1106px;
        height: 180px;
        margin-top: 59px;
    }

    .footer-in {
        width: 2000px;
        height: 466px;
        margin-top: 63px;
    }
}

@media (max-width: 1920px) and (min-width: 1441px) {
    .ayayi-brand {
        height: 20px;
        margin-top: 90px;
        font-size: 20px;

    }

    .hongkong {
        font-size: 20px;
        height: 20px;
        margin-top: 15px;
    }

    .icons {
        width: 133px;
        height: 32px;
        margin-top: 32px;
        gap: 19px;
    }

    .icons img {
        width: 32px;
        height: 32px;
    }

    .mobile-app {
        font-size: 20px;
        margin-bottom: 35px;
    }

    .google-apple {
        width: 275px;
        height: 140px;
    }

    .google-apple img {
        width: 172px;
        height: 51px;
    }

    .footer-right {
        width: 317px;
        height: 431px;
    }

    .footer-left-bottom {
        width: 309.494px;
        height: 32.057px;
        margin-top: 173px;
        font-size: 24px;
    }

    .text-title {
        margin-bottom: 32px;
        font-size: 24px;
    }

    .text-font {
        margin-bottom: 32px;
        font-size: 24px;
    }

    .text1 {
        width: 178px;
        height: 146px;
    }

    .text2 {
        width: 154px;
        height: 188px;
        margin-left: 62px;
    }

    .text3 {
        width: 141px;
        height: 87px;
        margin-left: 64px;
    }

    .text4 {
        width: 186px;
        height: 150px;
        margin-left: 78px;
    }

    .footer-left {
        width: 830px;
        margin-left: 46px;
    }

    .footer-left-top {
        width: 197.436px;
        height: 32.057px;
    }

    .footer-left-mid {
        width: 830px;
        height: 150px;
        margin-top: 40px;
    }

    .footer-in {
        width: 1488px;
        height: 423px;
        margin-top: 63px;
    }
}

@media (max-width: 1440px) and (min-width: 1201px) {

    .ayayi-brand {
        height: 14px;
        margin-top: 90px;
        font-size: 14px;

    }

    .hongkong {
        font-size: 14px;
        height: 14px;
        margin-top: 10px;
    }

    .icons {
        width: 100px;
        height: 24px;
        margin-top: 15px;
        gap: 14px;
    }

    .icons img {
        width: 24px;
        height: 24px;
    }

    .mobile-app {
        font-size: 20px;
        margin-bottom: 26px;
    }

    .google-apple {
        width: 206px;
        height: 104px;
    }

    .google-apple img {
        width: 128px;
        height: 38px;
    }

    .footer-right {
        width: 237px;
        height: 322px;
    }

    .footer-left-bottom {
        width: 232px;
        height: 24px;
        margin-top: 144px;
        font-size: 20px;
    }

    .text-title {
        margin-bottom: 18px;
        font-size: 20px;
    }

    .text-font {
        margin-bottom: 15px;
        font-size: 20px;
    }

    .text1 {
        width: 133px;
        height: 94px;
    }

    .text2 {
        width: 116px;
        height: 59px;
        margin-left: 24px;
    }

    .text3 {
        width: 106px;
        height: 59px;
        margin-left: 48px;
    }

    .text4 {
        width: 139px;
        height: 98px;
        margin-left: 58px;
    }

    .footer-left {
        width: 767px;
        margin-left: 46px;
    }

    .footer-left-top {
        width: 148px;
        height: 24px;
    }

    .footer-left-mid {
        width: 622px;
        height: 98px;
        margin-top: 30px;
    }

    .footer-in {
        width: 1188px;
        height: 323px;
        margin-top: 50px;
    }
}


@media (max-width: 1200px) and (min-width: 801px) {
    .ayayi-brand {
        height: 17px;
        font-size: 14px;
    }

    .hongkong {
        font-size: 14px;
        height: 17px;
        margin-top: 9px;
    }

    .footer-left-bottom {
        width: 232px;
        height: 24px;
        font-size: 20px;
    }

    .icons {
        width: 100px;
        height: 24px;
        gap: 14px;
    }

    .icons img {
        width: 24px;
        height: 24px;
    }

    .google-apple {
        width: 278px;
        flex-direction: row;
    }

    .google-apple img {
        width: 128px;
        height: 38px;
    }

    .footer-right-1 {

        width: 1068px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .footer-right-2 {
        width: 1068px;
        height: 42px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .footer-right {
        width: 1068px;
        height: 113px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .text-title {
        margin-bottom: 18px;
        font-size: 20px;
    }

    .text-font {
        margin-bottom: 15px;
        font-size: 20px;
    }

    .text1 {
        width: 133px;
        height: 94px;
    }

    .text2 {
        width: 116px;
        height: 59px;
        margin-left: 191px;
    }

    .text3 {
        width: 106px;
        height: 59px;
        margin-left: 191px;
    }

    .text4 {
        width: 145px;
        height: 98px;
        margin-left: 214px;
    }

    .footer-left {
        width: 1068px;
        border-bottom: 1px solid #878787;
    }

    .footer-left-mid {
        width: 1068px;
        height: 98px;
    }

    .footer-left-top {
        width: 148px;
        height: 24px;
        margin-top: 64px;
        margin-bottom: 16px;
    }

    .footer-in {
        width: 1068px;
        height: 337px;
        margin-top: 64px;
        margin-bottom: 32px;
        flex-direction: column;
    }
}

@media (max-width: 800px) and (min-width: 413px) {
    .ayayi-brand {
        height: 17px;
        font-size: 14px;
    }

    .hongkong {
        font-size: 14px;
        height: 17px;
        margin-top: 9px;
    }

    .footer-left-bottom {
        width: 232px;
        height: 24px;
        font-size: 20px;
    }

    .icons {
        width: 100px;
        height: 24px;
        gap: 14px;
    }

    .icons img {
        width: 24px;
        height: 24px;
    }

    .google-apple {
        width: 266px;
        flex-direction: row;
    }

    .google-apple img {
        width: 128px;
        height: 38px;
    }

    .footer-right-1 {
        width: 680px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
    }

    .footer-right-2 {
        width: 680px;
        height: 42px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
    }

    .footer-right {
        width: 680px;
        height: 156px;
        flex-direction: column;
        align-items: center;
    }

    .text-title {
        margin-bottom: 18px;
        font-size: 20px;
    }

    .text-font {
        margin-bottom: 15px;
        font-size: 20px;
    }

    .text1 {
        width: 133px;
        height: 94px;
    }

    .text2 {
        width: 116px;
        height: 59px;
        margin-left: 62px;
    }

    .text3 {
        width: 106px;
        height: 59px;
        margin-left: 62px;
    }

    .text4 {
        width: 145px;
        height: 98px;
        margin-left: 62px;
    }

    .footer-left {
        width: 680px;
        border-bottom: 1px solid #878787;
    }

    .footer-left-mid {
        width: 680px;
        height: 98px;
    }

    .footer-left-top {
        width: 148px;
        height: 24px;
        margin-top: 112px;
        margin-bottom: 16px;
    }

    .footer-in {
        width: 680px;
        height: 410px;
        margin-top: 64px;
        margin-bottom: 32px;
        flex-direction: column;
    }
}

@media (max-width: 414px) and (min-width: 376px) {
    .ayayi-brand {
        height: 11px;
        font-size: 9px;
        text-align: end;
    }

    .hongkong {
        font-size: 9px;
        height: 11px;
        margin-top: 4px;
    }

    .footer-left-bottom {
        width: 116px;
        height: 12px;
        font-size: 10px;
    }

    .icons {
        width: 50px;
        height: 12px;
        gap: 7px;
    }

    .icons img {
        width: 10px;
        height: 10px;
    }

    .google-apple {
        width: 131px;
        flex-direction: row;
    }

    .google-apple img {
        width: 63px;
        height: 18px;
    }

    .footer-right-1 {
        width: 335px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
    }

    .footer-right-2 {
        width: 335px;
        height: 26px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
    }

    .footer-right {
        width: 335px;
        height: 71px;
        flex-direction: column;
        align-items: center;
    }

    .text-title {
        margin-bottom: 18px;
        font-size: 10px;
    }

    .text-font {
        margin-bottom: 15px;
        font-size: 10px;
    }

    .text1 {
        width: 48px;
        height: 63px;
    }

    .text2 {
        width: 48px;
        height: 39px;
        margin-left: 43px;
    }

    .text3 {
        width: 42px;
        height: 39px;
        margin-left: 43px;
    }

    .text4 {
        width: 69px;
        height: 63px;
        margin-left: 43px;
    }

    .footer-left {
        width: 335px;
        border-bottom: 1px solid #878787;
    }

    .footer-left-mid {
        width: 335px;
        height: 63px;
    }

    .footer-left-top {
        width: 93.408px;
        height: 15.175px;
        margin-top: 36px;
        margin-bottom: 10px;
    }

    .footer-in {
        width: 335px;
        height: 205px;
        margin-top: 40px;
        margin-bottom: 20px;
        flex-direction: column;
    }
}

@media (max-width: 375px) {
    .ayayi-brand {
        height: 11px;
        font-size: 9px;
        text-align: end;
    }

    .hongkong {
        font-size: 9px;
        height: 11px;
        margin-top: 4px;
    }

    .footer-left-bottom {
        width: 116px;
        height: 12px;
        font-size: 10px;
    }

    .icons {
        width: 50px;
        height: 12px;
        gap: 7px;
    }

    .icons img {
        width: 10px;
        height: 10px;
    }

    .google-apple {
        width: 131px;
        flex-direction: row;
    }

    .google-apple img {
        width: 63px;
        height: 18px;
    }

    .footer-right-1 {
        width: 335px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
    }

    .footer-right-2 {
        width: 335px;
        height: 26px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
    }

    .footer-right {
        width: 335px;
        height: 71px;
        flex-direction: column;
        align-items: center;
    }

    .text-title {
        margin-bottom: 18px;
        font-size: 10px;
    }

    .text-font {
        margin-bottom: 15px;
        font-size: 10px;
    }

    .text1 {
        width: 48px;
        height: 63px;
    }

    .text2 {
        width: 48px;
        height: 39px;
        margin-left: 43px;
    }

    .text3 {
        width: 42px;
        height: 39px;
        margin-left: 43px;
    }

    .text4 {
        width: 69px;
        height: 63px;
        margin-left: 43px;
    }

    .footer-left {
        width: 335px;
        border-bottom: 1px solid #878787;
    }

    .footer-left-mid {
        width: 335px;
        height: 63px;
    }

    .footer-left-top {
        width: 93.408px;
        height: 15.175px;
        margin-top: 36px;
        margin-bottom: 10px;
    }

    .footer-in {
        width: 335px;
        height: 205px;
        margin-top: 40px;
        margin-bottom: 20px;
        flex-direction: column;
    }
} */