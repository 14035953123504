.Swiper-all {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Swiper-nft {
  position: relative;
  overflow: hidden;
}

.Swiper-nft img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.mySwiper .swiper-wrapper {

  /* 默认是ease-out */

  -webkit-transition-timing-function: linear;

  -ms-transition-timing-function: linear;

  -moz-transition-timing-function: linear;

  -o-transition-timing-function: linear;

  transition-timing-function: linear;

}

@media screen and (max-width: 2560px) {
  .Swiper-all {
    width: 100vw;
    height: 19.5vw;
  }

  .Swiper-nft {
    width: 15.3vw;
    height: 19.5vw;
  }
}

@media screen and (max-width: 480px) {
  .Swiper-all {
    width: 100vw;
    height: 76vw;
  }

  .Swiper-nft {
    width: 50vw;
    height: 76vw;
  }
}


/* @media (min-width: 2560px) {
  .Swiper-all {
    width: 2700px;
    height: 580px;
  }

  .Swiper-nft {
    width: 465.4px;
    height: 580px;
  }

}

@media (max-width: 2559px) and (min-width: 1920px) {
  .Swiper-all {
    width: 1920px;
    height: 420px;
  }

  .Swiper-nft {
    width: 349px;
    height: 420px;
  }

}

@media (max-width: 1919px) and (min-width: 1201px) {
  .Swiper-all {
    width: 1440px;
    height: 336px;
  }

  .Swiper-nft {
    width: 261.8px;
    height: 336px;
  }

}

@media (max-width: 1200px) and (min-width: 801px) {
  .Swiper-all {
    width: 1200px;
    height: 420px;
  }

  .Swiper-nft {
    width: 400px;
    height: 420px;
  }

}

@media (max-width: 800px) and (min-width: 413px) {
  .Swiper-all {
    width: 800px;
    height: 336px;
  }

  .Swiper-nft {
    width: 266.6px;
    height: 336px;
  }

}

@media (max-width: 414px) and (min-width: 376px) {
  .Swiper-all {
    width: 414px;
    height: 272px;
  }

  .Swiper-nft {
    width: 207px;
    height: 272px;
  }

}

@media (max-width: 375px) {
  .Swiper-all {
    width: 375px;
    height: 272px;
  }

  .Swiper-nft {
    width: 187.5px;
    height: 272px;
  }

} */


.Swiper-nft:hover {
  transform: scale(1.1);
  cursor: pointer;
}