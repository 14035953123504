.More {
    text-align: center;
    background-color: #060606;
    display: flex;
    flex-direction: column;
    font-family: 'Barlow Condensed';
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}

.More-navbar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
}

.more-body {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.more-page1 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("/public/background/more_bd1.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-blend-mode: overlay;
    background-color: rgba(255, 255, 255, 0.85);
}

.more-page1-in {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}


.more-page1-font {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: justify;
}

.more-page1-font-title {
    color: #000;
    font-weight: 600;
}

.more-page1-font-font {
    color: #707070;
}

.more-page1-img {
    position: relative;
    overflow: hidden;
}

.more-page1-img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* max-width: 100%; */
    max-height: 100%;
}

@media screen and (max-width: 2560px) {
    .more-body {
        margin-top: 2.3vw;
    }

    .more-page1-font {
        width: 43vw;
        height: 28vw;
        margin-bottom: 5.8vw;
    }

    .more-page1-font-title {
        width: 43vw;
        font-size: 4.6vw;
        line-height: 4.68vw;
    }

    .more-page1-font-font {
        width: 100%;
        margin-top: 2.4vw;
        font-size: 1.875vw;
        letter-spacing: 0.96px;
    }

    .more-page1-in {
        width: 78.43vw;
        height: 54.12vw;
    }

    .more-page1-img {
        width: 36.98vw;
        height: 47.7vw;
        margin-bottom: 2.4vw;
        margin-left: 3.84vw;
    }

}

@media screen and (max-width: 480px) {
    .more-body {
        margin-top: 8.5vw;
    }

    .more-page1-font {
        width: 100%;
        height: 40.00vw;
        margin-top: 5.87vw;
        /* margin-bottom: 22.67vw; */
    }

    .more-page1-font-title {
        width: 100%;
        /* height: 68.53vw; */
        font-size: 6.40vw;
        line-height: 6.40vw;
    }

    .more-page1-font-font {
        width: 100%;
        /* height: 17.07vw; */
        margin-top: 3.7vw;
        font-size: 3.20vw;
        line-height: 4.27vw;
        letter-spacing: 0.24px;
    }

    .more-page1-in {
        width: 94.67vw;
        height: 144.5vw;
        flex-direction: column-reverse;
        align-items: flex-end;
    }

    .more-page1-img {
        width: 60.00vw;
        height: 101.33vw;
        margin-top: 2.9vw;
    }

}



.more-page2 {
    width: 100%;
    background-image: url("/public/other/more-img2.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@media screen and (max-width: 2560px) {
    .more-page2 {
        height: 13.8vw;
    }
}

@media screen and (max-width: 480px) {
    .more-page2 {
        height: 13.8vw;
    }
}





.more-page3 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("/public/background/more_bd3.jpg");
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
    background-blend-mode: overlay;
    background-color: rgba(255, 255, 255, 0.85);
}

.more-page3-in {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.more-page3-in-title {
    color: #000;
    text-align: center;
    font-weight: 600;
}

.more-page3-in-img1 {
    position: relative;
    overflow: hidden;
}

.more-page3-in-img1 img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    /* max-height: 100%; */
}

.more-page3-in-font1 {
    color: #707070;
    text-align: justify;
}

.more-page3-in-img2 {
    position: relative;
    overflow: hidden;
}

.more-page3-in-img2 img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    /* max-height: 100%; */
}

.more-page3-in-font2 {
    color: #707070;
    text-align: justify;
}




.more-page4 {
    width: 100%;
    background-image: url("/public/other/more-img8.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@media screen and (max-width: 2560px) {
    .more-page4 {
        height: 20.8vw;
    }
}

@media screen and (max-width: 480px) {
    .more-page4 {
        height: 20.8vw;
    }
}


.more-page5 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url("/public/background/more_bd3.jpg"); */
    /* background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
    background-blend-mode: overlay; */
    background-color: rgba(255, 255, 255, 0.7);
}

.more-page5-in {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.more-page5-in-title {
    color: #000;
    font-weight: 600;
    text-align: justify;
}


.more-page5-in-img1 {
    display: flex;
    justify-content: space-between;
}

.more-page5-in-img1-1 {
    position: relative;
    overflow: hidden;
}

.more-page5-in-img1-1 img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* max-width: 100%; */
    max-height: 100%;
}

.more-page5-in-img1-2 {
    position: relative;
    overflow: hidden;
}

.more-page5-in-img1-2 img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* max-width: 100%; */
    max-height: 100%;
}

.more-page5-in-font1 {
    color: #707070;
    text-align: justify;
}

.more-page5-in-img2 {
    position: relative;
    overflow: hidden;
}

.more-page5-in-img2 img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* max-width: 100%; */
    max-height: 100%;
}

.more-page5-in-font2 {
    color: #707070;
    text-align: justify;
}

.more-in-add-imgs {
    display: flex;
    width: 100%;
}

.more-in-add-img {
    position: relative;
    overflow: hidden;
}

.more-in-add-img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* max-width: 100%; */
    max-height: 100%;
}


@media screen and (max-width: 2560px) {
    .more-in-add-imgs {
        margin-top: 5vw;
        height: 25vw;
        gap: 2vw;
    }

    .more-in-add-img {
        width: 18vw;
        height: 25vw;
    }

    .more-in-add-page3-img1 {
        width: 100%;
        height: 31.6vw;
        margin-top: 3.8vw;
        margin-bottom: 1.7vw;
    }

    .more-in-add-page5-img1 {
        width: 100%;
        height: 50vw;
        margin-top: 6.8vw;
        margin-bottom: 5.7vw;
    }

    .more-in-add-top {
        width: 100%;
        height: 5vw;
        display: flex;
        align-items: center;
        margin-bottom: 2.7vw;
        margin-top: 2.7vw;
    }

    .more-in-add-top-1 {
        width: 0.97vw;
        height: 2.7vw;
        background-color: #c3c3c3;
    }

    .more-in-add-top-2 {
        height: 5vw;
        font-size: 3.75vw;
        margin-left: 2vw;
        color: #c3c3c3;
    }

    .more-in-add-top-3 {
        height: 2.7vw;
        border-top: 1px solid #c3c3c3;
        flex: 1
    }



    .more-page5-in-img1-1 {
        width: 26.02vw;
        height: 39.06vw;
    }

    .more-page5-in-img1-2 {
        width: 26.02vw;
        height: 39.06vw;
    }

    .more-page5-in-font1 {
        width: 100%;
        margin-top: 3.4vw;
        font-size: 1.875vw;
        line-height: 1.875vw;
        letter-spacing: 0.96px;
    }

    .more-page5-in-img2 {
        width: 42.97vw;
        height: 61.77vw;
        margin-top: 3.6vw;
    }

    .more-page5-in-font2 {
        width: 100%;
        margin-top: 1.76vw;
        font-size: 1.875vw;
        line-height: 1.875vw;
        letter-spacing: 0.96px;
    }

    .more-page5-in {
        width: 78.13vw;
        /* height: 149.77vw; */
        margin-top: 7.52vw;
        margin-bottom: 5.31vw;
    }

    .more-page5-in-title {
        width: 100%;
        font-size: 5.00vw;
        line-height: 5.00vw;
    }

    .more-page5-in-img1 {
        width: 52.73vw;
        height: 39.06vw;
        margin-top: 3vw;
        gap: 0.4vw;
    }

}

@media screen and (max-width: 480px) {
    .more-in-add-imgs {
        margin-top: 8vw;
        margin-bottom: 8vw;
        height: 30vw;
        gap: 2.4vw;
    }

    .more-in-add-img {
        width: 21.6vw;
        height: 30vw;
    }

    .more-in-add-page3-img1 {
        width: 100%;
        height: 38vw;
        margin-top: 3.8vw;
        margin-bottom: 0.7vw;
    }

    .more-in-add-page5-img1 {
        width: 100%;
        height: 60vw;
        margin-top: 6.8vw;
        margin-bottom: 5.7vw;
    }

    .more-in-add-top {
        width: 100%;
        height: 7.7vw;
        display: flex;
        align-items: center;
        margin-bottom: 2.4vw;
    }

    .more-in-add-top-1 {
        width: 1.87vw;
        height: 4.4vw;
        background-color: #c3c3c3;
    }

    .more-in-add-top-2 {
        height: 7.7vw;
        font-size: 6.4vw;
        margin-left: 2vw;
        color: #c3c3c3;
    }

    .more-in-add-top-3 {
        height: 4.4vw;
        border-top: 1px solid #c3c3c3;
        flex: 1
    }


    .more-page5-in-img1-1 {
        width: 46.93vw;
        height: 70.40vw;
    }

    .more-page5-in-img1-2 {
        width: 46.93vw;
        height: 70.40vw;
    }

    .more-page5-in-font1 {
        width: 100%;
        margin-top: 10.9vw;
        font-size: 3.20vw;
        line-height: 4.27vw;
        letter-spacing: 0.24px;
    }

    .more-page5-in-img2 {
        width: 64.8vw;
        height: 86vw;
        margin-top: 6.4w;
    }

    .more-page5-in-font2 {
        width: 100%;
        margin-top: 7.2vw;
        font-size: 3.20vw;
        line-height: 4.27vw;
        letter-spacing: 0.24px;
    }

    .more-page5-in {
        width: 94.67vw;
        /* height: 256.67vw; */
        margin-top: 14.9vw;
        margin-bottom: 12vw;
    }

    .more-page5-in-title {
        width: 100%;
        text-align: left;
        font-size: 6.40vw;
        line-height: 6.40vw;
    }

    .more-page5-in-img1 {
        width: 100%;
        height: 70.40vw;
        margin-top: 7.5vw;
        gap: 1.3vw;
    }

}



.more-page7 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #080808;
}

.more-page7-in {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.more-page7-in-title {
    color: #fff;
    font-weight: 600;
    text-align: left;
}

.more-page7-in-part {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.more-page7-in-down {
    font-style: italic;
    color: #707070;
}

.more-page7-in-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.more-page7-in-group-img {}

.more-page7-in-group-code {
    color: #FFF;
    font-weight: 500;
}

.more-page7-in-group-title {
    color: #FFF;
    font-weight: 500;
}

.more-page7-in-group-font {
    color: #707070;
}

.more-page7-in-group-font2 {
    color: #707070;
}


@media screen and (max-width: 2560px) {
    .more-page7-in-group {
        width: 28.4vw;
        height: 25.9vw;
    }

    .more-page7-in-group-img {
        width: 8.28vw;
        height: 8.28vw;
    }

    .more-page7-in-group-code {
        width: 3.02vw;
        height: 1.58vw;
        font-size: 1.41vw;
        margin-top: 2.4vw;
        line-height: 1.11vw;
        letter-spacing: 0.72px;
    }

    .more-page7-in-group-title {
        width: 100%;
        margin-top: 1.6vw;
        font-size: 2.8vw;
    }

    .more-page7-in-group-font {
        width: 100%;
        margin-top: 1.2vw;
        font-size: 1.4vw;
    }

    .more-page7-in-group-font2 {
        width: 100%;
        margin-top: 1vw;
        font-size: 0.9vw;
    }

    .more-page7-in {
        width: 78.125vw;
        height: 78.6vw;
        margin-top: 8.2vw;
    }

    .more-page7-in-title {
        width: 100%;
        font-size: 5vw;
        line-height: 5vw;
    }

    .more-page7-in-part {
        width: 100%;
        height: 51vw;
        margin-top: 8vw;
        column-gap: 9.8vw;
    }

    .more-page7-in-down {
        width: 67.42vw;
        margin-top: 8.4vw;
        margin-bottom: 2.5vw;
        font-size: 1vw;
    }

}

@media screen and (max-width: 480px) {
    .more-page7-in-group {
        width: 100%;
        height: 33.00vw;
        flex-direction: row;
        align-items: flex-start;
    }

    .more-page7-in-group-img {
        width: 24.00vw;
        height: 24.00vw;
    }

    .more-page7-in-group-code {
        width: 12.27vw;
        height: 6.40vw;
        font-size: 3.20vw;
        line-height: 7.60vw;
        letter-spacing: 0.24px;
    }

    .more-page7-in-group-title {
        text-align: justify;
        width: 53.60vw;
        /* height: 6.67vw; */
        font-size: 5.33vw;
    }

    .more-page7-in-group-font {
        text-align: justify;
        width: 53.61vw;
        /* height: 15.47vw; */
        font-size: 3.20vw;
    }

    .more-page7-in-group-font2 {
        width: 53.61vw;
        text-align: left;
        margin-top: 1vw;
        font-size: 2.66vw;
    }

    .more-page7-in {
        width: 94.67vw;
        height: 188vw;
        margin-top: 14vw;
    }

    .more-page7-in-title {
        width: 94.40vw;
        height: 15.20vw;
        font-size: 6.40vw;
        line-height: 6.40vw;
    }

    .more-page7-in-part {
        width: 100%;
        column-gap: 10.7vw;
        height: 178vw;
    }

    .more-page7-in-down {
        width: 94.40vw;
        height: 10.73vw;
        margin-top: 2.4vw;
        margin-bottom: 2.5vw;
        font-size: 3.20vw;
    }

}


.more-footer {
    width: 100%;
    /* height: 420px; */
    display: flex;
    justify-content: center;
    color: #fff;
}