.Login {
    text-align: center;
    align-items: center;
    background-color: #080808;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}

.login-navbar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
}


.login-header {
    justify-content: center;
    position: relative;
    z-index: 2;
}

.body-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.body-content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/public/background/login-bg.jpg");
    background-size: cover;
    filter: blur(10px) brightness(0.9);
}

.body-content-pc {
    width: 26vw;
    height: 35.7vw;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 8vw; */
}

.body-content-pc::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/public/background/login-bg.jpg");
    background-size: cover;
    filter: blur(10px) brightness(0.9);
}


.form-login {
    background-color: rgba(6, 6, 6, 0.7);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Barlow';
    width: 100%;
    height: 100%;
}

.form-login-pc {
    background-color: rgba(6, 6, 6, 0.7);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Barlow';
    width: 100%;
    height: 100%;
}

.form1-pc {
    display: flex;
    width: 80%;
    height: 8.2vw;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 3.6vw;
}

.form2-pc {
    display: flex;
    width: 80%;
    height: 18.6vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2vw;
}

.form1 {
    display: flex;
    width: 80%;
    height: 34.4vw;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 24.5vw;
}

.form2 {
    display: flex;
    width: 80%;
    height: 75.5vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 13.9vw;
}

.title-font {
    color: #fff;
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: left;
    font-weight: 600;
}

.title2-font {
    width: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-weight: 600;
    letter-spacing: 1px;
}

@media screen and (max-width: 2560px) {

    .title-font {
        font-size: 2.5vw;
    }

    .title2-font {
        font-size: 1.25vw;
        margin-top: 0.7vw;
    }
}

@media screen and (max-width: 480px) {

    .title-font {
        font-size: 10.7vw;
    }

    .title2-font {
        font-size: 5.3vw;
    }
}

.input-address-code-pc {
    width: 100%;
    height: 6vw;
    color: #A4A4A4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.input-address-code {
    width: 100%;
    height: 20.4vw;
    color: #A4A4A4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.address-warnning-pc {
    width: 100%;
    height: 3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.address-warnning {
    width: 100%;
    height: 11.7vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.input-address-pc {
    width: 100%;
    display: flex;
    height: 2vw;
    border-bottom: solid 1px #A4A4A4;
}


.input-address {
    width: 100%;
    height: 8vw;
    display: flex;
    border-bottom: solid 1px #A4A4A4;
}

.warnning {
    width: 100%;
    height: 14px;
    font-size: 12px;
    color: #EA4335;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4px;
}

.address,
.code {
    background-color: transparent;
    width: 100%;
    padding: 0 8px;
    color: #fff;
    border: 0 solid #ccc;
    /* padding: 0.5rem; */
    /* border-radius: 0.25rem; */
}

.resent-code {
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 2560px) {

    .address,
    .code,
    .resent-code {
        font-size: 0.7vw;
    }
}

@media screen and (max-width: 480px) {

    .address,
    .code,
    .resent-code {
        font-size: 3.7vw;
    }
}

.code-warnning-pc {
    width: 100%;
    height: 3vw;
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.code-warnning {
    width: 100%;
    height: 11.7vw;
    margin-top: 1.6vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.input-code-pc {
    width: 100%;
    height: 2vw;
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #A4A4A4;
}

.input-code {
    width: 100%;
    height: 8vw;
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #A4A4A4;
}

.next-button-pc {
    cursor: pointer;
    width: 100%;
    height: 2.5vw;
    border: 2px solid #D5D5D5;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    color: #fff;
    font-weight: 700;
    margin-top: 1.8vw;
    font-size: 0.9vw;
}


.next-button {
    width: 100%;
    height: 10.7vw;
    border: 2px solid #D5D5D5;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    color: #fff;
    font-weight: 700;
    font-size: 3.5vw;
    margin-top: 5.6vw;
}

.next-button:hover,
.next-button-pc:hover {
    background-color: #2C2C2C;
    /* top: -3px; */
    /* 向上移动的距离 */
    /* transform: scale(1.1); */
    /* 放大效果 */
}

.line-with-text-pc {
    width: 50%;
    height: 0.7vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #D5D5D5;
    margin-top: 0.9vw;
}

.line-with-text {
    width: 50%;
    height: 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #D5D5D5;
    margin-top: 6.4vw;
}

.font1 {
    height: 1px;
    border-bottom: 1px solid #D5D5D5;
}

.font2 {
    margin: 0 10px;
}

@media screen and (max-width: 2560px) {
    .font1 {
        min-width: 4.7vw;
    }

    .font2 {
        font-size: 0.6vw;
    }
}

@media screen and (max-width: 480px) {
    .font1 {
        min-width: 15.7vw;
    }

    .font2 {
        font-size: 3.2vw;
    }
}

.logins-pc {
    display: flex;
    height: 1.25vw;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.4vw;
    gap: 0.5vw;
}

.logins {
    display: flex;
    width: 30%;
    height: 5vw;
    justify-content: space-between;
    align-items: center;
    margin-top: 2.9vw;
    gap: 3.7vw;
}

.lg {
    cursor: pointer;
    position: relative;
    top: 0;
    transition: top 0.3s ease-in-out;
}

.lg:hover {
    /* top: -2px; */
    /* 向上移动的距离 */
    transform: scale(1.2);
    /* 放大效果 */
}

.belongs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9F9F9F;
}

@media screen and (max-width: 2560px) {
    .lg {
        width: 1.25vw;
        height: 1.25vw;
    }

    .belongs {
        font-size: 0.625vw;
        margin-top: 0.4vw;
    }
}

@media screen and (max-width: 480px) {
    .lg {
        width: 5vw;
        height: 5vw;
    }

    .belongs {
        font-size: 2.7vw;
        margin-top: 6.1vw;
    }
}

.and {
    color: #9F9F9F;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
}

@media screen and (max-width: 2560px) {
    .and {
        font-size: 0.625vw;
    }
}

@media screen and (max-width: 480px) {
    .and {
        font-size: 2.7vw;
    }
}

.import {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.policy-services {
    color: #fff;
}

.policy-services a {
    color: #fff;
    text-decoration: none;
}

@media screen and (max-width: 2560px) {
    .policy-services {
        font-size: 0.6vw;
    }
}

@media screen and (max-width: 480px) {
    .policy-services {
        font-size: 2.7vw;
    }
}

.footerLogin {
    width: 100%;
    /* height: 42px; */
    background-color: #080808;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99999;
}



input {
    outline: none;
}