.About {
  text-align: center;
  background-color: #060606;
  display: flex;
  flex-direction: column;
  font-family: 'Barlow Condensed';
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.About-navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.about-body {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.about-page1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-weight: 600;
  background-image: url("/public/background/about-img-bd1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}


.about-page1-font {
  z-index: 1;
  font-family: 'Barlow';
}

@media screen and (max-width: 2560px) {

  .about-page1 {
    height: 18.75vw;
    margin-top: 2.3vw;
  }

  .about-page1-font {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 480px) {

  .about-page1 {
    height: 32vw;
    margin-top: 8.5vw;
  }

  .about-page1-font {
    font-size: 4.2vw;
  }
}




.about-page2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-page2-in {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about-page2-in-left {
  position: relative;
  overflow: hidden;
}

.about-page2-in-left img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}


.about-page2-in-right {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.about-page2-in-right-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
}

.about-page2-in-right-top-img {}


.about-page2-in-right-mid {
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;
}


.mid-p {
  margin: 0;
  padding: 0;
  display: flex;
}


.mid-p-div {}


.about-page2-in-right-bottom {
  color: #707070;
}


@media screen and (max-width: 2560px) {
  .about-page2-in-right-top {
    width: 100%;
    height: 3vw;
  }

  .about-page2-in-right-top-img {
    width: 18.75vw;
    height: 3vw;
  }


  .about-page2-in-right-mid {
    width: 100%;
    font-size: 2.5vw;
    margin-top: 5vw;
  }

  .mid-p {
    width: 100%;
    text-align: left;
    justify-content: flex-start;
  }

  .mid-p-div {
    border-bottom: 4px solid #DCDCDC;
  }

  .about-page2-in-right-bottom {
    width: 100%;
    margin-top: 3.67vw;
    text-align: justify;
    text-justify: inter-word;
    font-size: 1.4vw;
    line-height: 1.4vw;
    letter-spacing: 0.4px;
  }

  .about-page2-in {
    width: 78.13vw;
    margin-top: 4.38vw;
    margin-bottom: 3.52vw;
  }

  .about-page2-in-left {
    width: 39.7vw;
    height: 44.8vw;
  }

  .about-page2-in-right {
    width: 32.5vw;
  }

}

@media screen and (max-width: 480px) {
  .about-page2-in-right-top {
    width: 100%;
    height: 5.3vw;
  }

  .about-page2-in-right-top-img {
    width: 32.8vw;
    height: 5.3vw;
  }

  .about-page2-in-right-mid {
    width: 100%;
    font-size: 5.3vw;
    line-height: 6.4vw;
    margin-top: 13vw;
  }

  .mid-p {
    width: 100%;
    justify-content: center;
  }

  .mid-p-div {
    border-bottom: 4px solid #DCDCDC;
  }


  .about-page2-in-right-bottom {
    width: 83.2vw;
    margin-top: 9.3vw;
    font-size: 3.2vw;
    line-height: 4.2vw;
    letter-spacing: 0.24px;
  }

  .about-page2-in {
    width: 93.6vw;
    flex-direction: column-reverse;
  }

  .about-page2-in-left {
    width: 100%;
    height: 57.8vw;
    margin-top: 14.1vw;
  }

  .about-page2-in-right {
    width: 100%;
    margin-top: 9.8vw;
  }
}


.about-page3 {
  width: 100%;
  height: 16vw;
  background-image: url("/public/other/about-img6.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.about-page4 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-page4-in {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-page4-in-top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.about-page4-in-top-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  text-align: justify;
}

.about-page4-in-top-right {}

.about-page4-in-top-left-title {
  width: 100%;
  color: #000;
  font-weight: 600;
}

.about-page4-in-top-left-title2 {
  width: 100%;
  display: flex;
  color: #707070;
  font-weight: 600;
  align-items: center;
}

.about-page4-in-top-left-font {
  width: 100%;
  color: #707070;
}



.about-page4-in-mid {
  position: relative;
  overflow: hidden;
}

.about-page4-in-mid img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  /* max-height: 100%; */
}

.about-page4-in-bottom {
  color: #707070;
  text-align: justify;
}


@media screen and (max-width: 2560px) {
  .about-page4-in-mid {
    width: 100%;
    height: 21.4vw;
    margin-top: 3.6vw;
  }

  .about-page4-in-bottom {
    margin-top: 4.96vw;
    width: 100%;
    /* height: 12.783203125vw; */
    font-size: 1.875vw;
    letter-spacing: 0.96px;
  }

  .about-page4-in {
    width: 78.125vw;
    margin-top: 4.8828125vw;
    margin-bottom: 6.4vw;
  }

  .about-page4-in-top {
    /* height: 20.3125vw; */
  }

  .about-page4-in-top-left {
    width: 72.421875vw;
  }

  .about-page4-in-top-left-title {
    /* height: 9.21875vw; */
    font-size: 5vw;
    line-height: 5vw;
  }

  .about-page4-in-top-left-title2 {
    /* height: 9.21875vw; */
    margin-top: 1.7vw;
    font-size: 3.75vw;
    line-height: 3.75vw;
  }

  .about-page4-in-top-left-title2-mdash {
    width: 6.25vw;
    border: 2px solid #707070;
  }

  .about-page4-in-top-left-font {
    /* height: 7.8125vw; */
    margin-top: 1.95vw;
    font-size: 1.875vw;
    letter-spacing: 0.96px;
  }

  .about-page4-in-top-right {
    width: 1.9vw;
    height: 17.7vw;
  }


}

@media screen and (max-width: 480px) {
  .about-page4-in-mid {
    width: 100%;
    height: 29vw;
    margin-top: 8.5vw;
  }

  .about-page4-in-bottom {
    margin-top: 10.67vw;
    width: 100%;
    font-size: 3.20vw;
    line-height: 4.27vw;
    letter-spacing: 0.24px;
  }

  .about-page4-in-top {}

  .about-page4-in-top-left {
    width: 100%;
  }

  .about-page4-in-top-left-title {
    font-size: 6vw;
    line-height: 6.40vw;
  }

  .about-page4-in-top-left-title2 {
    font-size: 6.40vw;
    line-height: 6.40vw;
    margin-top: 3.7vw;
  }

  .about-page4-in-top-left-title2-mdash {
    width: 6.4vw;
    border: 1px solid #707070;
  }

  .about-page4-in-top-left-font {
    margin-top: 3.7vw;
    font-size: 3.20vw;
    letter-spacing: 0.24px;
  }

  .about-page4-in {
    width: 93.6vw;
    margin-top: 11.7vw;
    margin-bottom: 9.8vw;
  }

}







.about-page5-pc {
  width: 108.3vw;
  gap: 1.8vw;
  display: flex;
  background: linear-gradient(to bottom, transparent 50%, #080808 50%);

}

.about-page5-img-pc {
  width: 25.7vw;
  height: 13.4vw;
  position: relative;
  overflow: hidden;
}

.about-page5-img-pc img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  /* max-height: 100%; */
}

.about-page5 {
  width: 165.3vw;
  gap: 2.93vw;
  display: flex;
  background: linear-gradient(to bottom, transparent 50%, #080808 50%);

}

.about-page5-img {
  width: 39.2vw;
  height: 32.3vw;
  position: relative;
  overflow: hidden;
}

.about-page5-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  /* max-height: 100%; */
}





.about-footer {
  width: 100%;
  /* height: 420px; */
  display: flex;
  justify-content: center;
  color: #fff;
}