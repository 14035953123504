.logo {
    cursor: pointer;
}

.right {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Barlow';
}


.about {
    color: #FFF;
    cursor: pointer;
}

.about-in {
    position: relative;
}

.about-in::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: #fff;
    animation: underline-animation 0.5s linear forwards;
}

@keyframes underline-animation {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

.store {
    cursor: pointer;
}



.login-in,
.logined-wallet {
    cursor: pointer;
    text-align: center;
    background-color: #080808;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    /* overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
}




.logined-wallet div {
    text-align: center;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}



.login-in:hover {
    background-color: #2C2C2C;
}

.navbar1 {
    width: 100%;
    background-color: #080808;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logined {
    display: flex;
    align-items: center;
    justify-content: center;
}


.logined-wallet:hover {
    background-color: #2C2C2C;
}


.logined-avatar {
    cursor: pointer;
    border-radius: 50%;
    color: #FFF;
    border: 1px solid #fff;
}

.logined-avatar img {
    border-radius: 50%;
}



@media screen and (max-width: 2560px) {
    .logined-avatar {
        width: 1.25vw;
        height: 1.25vw;
        margin-left: 1.6vw;
        font-size: 0.4vw;
    }

    .navbar1 {
        height: 2.3vw;
    }

    .login-in,
    .logined-wallet {
        width: 5.4vw;
        height: 1.3vw;
        font-size: 0.6vw;
        line-height: 0.6vw;
    }

    .logined-wallet div {
        width: 5.4vw;
        font-size: 0.6vw;
        line-height: 0.6vw;
        margin: 0 6px;
    }

    .logo {
        width: 5.6vw;
        margin-left: 1.6vw;
    }

    .right {
        margin-right: 1.6vw;
        gap: 2vw;
    }

    .about {
        width: 2.6vw;
        /* height: 20px; */
        font-size: 0.6vw;
    }

    .store {
        width: 1.2vw;
    }
}

@media screen and (max-width: 480px) {
    .logined-avatar {
        width: 5.3vw;
        height: 5.3vw;
        margin-left: 2.6vw;
        font-size: 3.2vw;
    }

    .navbar1 {
        height: 8.5vw;
    }

    .login-in,
    .logined-wallet {
        width: 22.4vw;
        height: 5.3vw;
        font-size: 3.2vw;
        line-height: 4.2vw;
    }

    .logined-wallet div {
        width: 22.4vw;
        font-size: 3.2vw;
        line-height: 3.2vw;
        margin: 0 6px;
    }

    .logo {
        width: 20.8vw;
        /* height: 12px; */
        margin-left: 5.3vw;
    }

    .right {
        margin-right: 5.3vw;
    }

    .about {
        width: 12.8vw;
        /* height: 14px; */
        font-size: 3.2vw;
        margin-right: 4.8vw;
    }

    .store {
        width: 5.3vw;
        /* height: 5.3vw; */
        margin-right: 4.8vw;
    }

}







/* 
@media (min-width: 1441px) {

}


@media (max-width: 1440px) and (min-width: 1201px) {
    .navbar1 {
        height: 42px;
    }

    .login-in,
    .logined-wallet {
        width: 116px;
        height: 28px;
        font-size: 14px;
        line-height: 14px;
    }

    .logined-wallet div {
        width: 116px;
        font-size: 14px;
        line-height: 14px;
        margin: 0 6px;
    }

    .logo {
        width: 92px;
        height: 15px;
        margin-left: 24px;
    }

    .right {
        margin-right: 24px;
    }

    .about {
        width: 60px;
        height: 20px;
        font-size: 14px;
        margin-right: 48px;
    }

    .store {
        width: 26px;
        height: 26px;
        margin-right: 48px;
    }
}

@media (max-width: 1200px) and (min-width: 801px) {
    .navbar1 {
        height: 60px;
    }

    .login-in,
    .logined-wallet {
        width: 139px;
        height: 34px;
        font-size: 16px;
        line-height: 16px;
    }

    .logined-wallet div {
        width: 139px;
        font-size: 16px;
        line-height: 16px;
        margin: 0 6px;
    }

    .logo {
        width: 142px;
        height: 24px;
        margin-left: 40px;
    }

    .right {
        margin-right: 40px;
    }

    .about {
        width: 64px;
        height: 20px;
        font-size: 16px;
        margin-right: 53px;
    }

    .store {
        width: 31px;
        height: 31px;
        margin-right: 57px;
    }
}

@media (max-width: 800px) and (min-width: 413px) {
    .navbar1 {
        height: 42px;
    }

    .login-in,
    .logined-wallet {
        width: 116px;
        height: 28px;
        font-size: 14px;
        line-height: 14px;
    }

    .logined-wallet div {
        width: 116px;
        font-size: 14px;
        line-height: 14px;
        margin: 0 6px;
    }

    .logo {
        width: 92px;
        height: 15px;
        margin-left: 24px;
    }

    .right {
        margin-right: 24px;
    }

    .about {
        width: 60px;
        height: 20px;
        font-size: 14px;
        margin-right: 44px;
    }

    .store {
        width: 26px;
        height: 26px;
        margin-right: 40px;
    }
}

@media (max-width: 414px) {
   
} */