.gallery {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.gallery::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.image-gallery img {
    border: 1px solid #010101;
}

.image-gallery img:hover {
    transform: scale(1.1);
}


.image-gallery {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.no-more {
    color: #707070;
    font-family: 'Barlow Condensed';
    font-weight: 350;
}

@media screen and (max-width: 2560px) {
    .gallery {
        min-height: 23.39vw;
        max-height: 46.875vw;
        width: 72.4vw;
    }

    .image-gallery {
        width: 60.7vw;
        gap: 0 6.64vw;
    }

    .image-gallery img {
        height: 22.1vw;
        width: 15.46vw;
        margin: 1.28vw 0;
    }

    .no-more {
        width: 72.4vw;
        height: 1.36vw;
        margin-top: 0.89vw;
        font-size: 1.25vw;
    }
}

@media screen and (max-width: 480px) {
    .gallery {
        min-height: 129vw;
        max-height: 242vw;
        width: 89.3vw;
    }

    .image-gallery {
        width: 89.3vw;
        gap: 0 2.6vw;
    }

    .image-gallery img {
        height: 62.1vw;
        width: 42.67vw;
        margin: 1.6vw 0;
    }

    .no-more {
        width: 89.3vw;
        height: 5vw;
        /* margin-top: -13px; */
        font-size: 3.7vw;
    }
}