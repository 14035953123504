.footer2 {
    height: 42px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.footer2-right {
    margin-right: 24px;
    color: #C3C3C3;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 12px;
    font-family: 'Barlow';
}

.footer2-right a {
    color: #7d7c7c;
    font-size: 10px;
    text-decoration: none;
}