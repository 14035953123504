.User {
  text-align: center;
  background-color: #060606;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

@media (max-width: 1024px) and (min-width: 540px) {
  .User {
    height: 100vh;
  }
}

.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.User-body {
  width: 100%;
  background-color: linear;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom, #050505 100%, #151212 100%, #070707 100%);

}


.body-top {
  width: 100%;
  display: flex;
  font-family: 'Barlow';
  justify-content: center;
  align-items: flex-end;
  background-image: url("/public/background/user-bd-2560.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
}

.top-all {
  display: flex;
  flex-direction: column;
}

.avatar {
  border: 1px solid #A1A1A1;
  border-radius: 50%;
}

.avatar img {
  border-radius: 50%;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-name {
  color: #fff;
  text-align: left;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.info-edit {
  color: #fff;
  font-weight: 900;
}

.info-id {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #A1A1A1;
  font-weight: 600;
}

.body-center {}

.wallet-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.wallet-top-left {
  display: flex;
  justify-content: space-between;
}

.wallet-top-left-icon {}

.wallet-top-left-font {
  color: #FFF;
  font-weight: 700;
}

.wallet-top-right {}

.wallet-bottom {
  display: flex;
  font-family: 'Barlow Condensed';
  justify-content: space-between;
  align-items: flex-start;
}

.wallet-bottom-left {
  display: flex;
  justify-content: space-between;
}

.wallet-bottom-right {
  height: 100%;
  width: 19px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.wallet-bottom-left-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-image: url("/public/other/user-wallet.svg");
  background-size: cover;
}

.wallet-info-title {
  text-align: left;
  color: #FFF;
}

.wallet-button {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #FFF;
  line-height: normal;
  font-weight: 500;
  text-align: center;

}

.wallet-connect-button {
  cursor: pointer;
  border: 1px solid #fff;
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
}


.wallet-connect-button:hover {
  transform: translateY(-1px);
}


.wallet-bottom-left-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.wallet-bottom-left-right-title {
  color: #fff;
}

.wallet-bottom-left-right-font {
  color: #fff;
}

.body-bottom {}

.nft-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}


.nft-top-left {
  display: flex;
  justify-content: space-between;
}

.nft-top-left-icon {}

.nft-top-left-font {
  color: #FFF;
  font-family: 'Barlow Condensed';
  font-weight: 700;
}

.nft-top-right {}


.nft-mid {
  text-align: left;
  color: #FFF;
  font-family: 'Barlow';
  font-weight: 600;
}

.nft-bottom {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.nft-show {
  background-color: #181818;
}


@media screen and (max-width: 2560px) {
  .nft-bottom {
    margin-top: 1.75vw;
    width: 72.4vw;
    height: 51vw;
  }

  .nft-show {
    width: 100%;
    height: 51vw;
  }

  .nft-mid {
    width: 11vw;
    height: 0.8vw;
    margin-top: 1vw;
    font-size: 0.9vw;
    letter-spacing: 1.2px;
  }

  .nft-top-left {
    width: 8.1vw;
    height: 2vw;
  }

  .nft-top-left-icon {
    width: 2vw;
    height: 2vw;
    margin-right: 1.7vw;
  }

  .nft-top-left-font {
    width: 4.3vw;
    height: 2vw;
    font-size: 1.25vw;
    letter-spacing: 1.6px;
  }

  .nft-top-right {

    width: 1.75vw;
    height: 1.75vw;
  }

  .body-bottom {
    width: 72.4vw;
    height: 54.5vw;
    margin-top: 1.4vw;
  }

  .nft-top {
    width: 100%;
    height: 1.8vw;
  }


  .wallet-bottom-left-right {
    width: 20.9vw;
    margin-left: 3vw;
  }

  .wallet-bottom-left-right-title {
    width: 20.9vw;
    height: 3vw;
    font-size: 1.25vw;
    letter-spacing: 0.64px;
  }

  .wallet-bottom-left-right-font {
    width: 20.9vw;
    height: 4.3vw;
    margin-top: 0.7vw;
    font-size: 0.7vw;
    letter-spacing: 0.4px;
  }

  .wallet-button {
    width: 12vw;
    font-size: 1vw;
  }

  .wallet-connect-button {
    width: 12.4vw;
    height: 2.6vw;
    margin-top: 1.75vw;
    margin-left: 2.9vw;
  }

  .wallet-bottom-left-left {
    width: 30vw;
    height: 12vw;
  }

  .wallet-info-title {
    width: 14vw;
    height: 3.75vw;
    margin-top: 2.1vw;
    margin-left: 3.4vw;
    font-size: 1.4vw;
  }


  .wallet-bottom-left {
    width: 54vw;
    height: 12vw;
  }

  .wallet-bottom {
    width: 100%;
    height: 12vw;
    margin-top: 1.32vw;
  }

  .wallet-top-left {
    width: 13vw;
    height: 2vw;
  }

  .wallet-top-left-icon {
    width: 2vw;
    height: 2vw;
    margin-right: 1.7vw;
  }

  .wallet-top-left-font {
    width: 8.4vw;
    height: 2vw;
    font-size: 1.25vw;
    /* letter-spacing: 1px; */
  }

  .wallet-top-right {
    width: 1.75vw;
    height: 1.75vw;
  }


  .body-center {
    width: 72.4vw;
    height: 15vw;
    margin-top: 3.1vw;
  }

  .wallet-top {
    width: 100%;
    height: 1.75vw;
  }

  .info-id {
    width: 8vw;
    height: 1.3vw;
    font-size: 0.9vw;
  }

  .info {
    width: 100%;
    height: 4.5vw;
    margin-top: 0.3vw;
  }

  .info-name {
    width: 20vw;
    height: 3.2vw;
    font-size: 2.5vw;
    letter-spacing: 3.2px;
  }

  .info-edit {
    width: 2.9vw;
    height: 1.67vw;
    font-size: 1.25vw;
  }

  .avatar {
    width: 8.4vw;
    height: 8.4vw;
  }

  .top-all {
    width: 72.4vw;
    margin-top: 8.2vw;
    height: 13vw;
  }

  .body-top {
    margin-top: 2.3vw;
  }
}

@media screen and (max-width: 480px) {
  .nft-bottom {
    margin-top: 5.07vw;
    width: 89.33vw;
    height: 136vw;
  }

  .nft-show {
    width: 89.33vw;
    height: 136vw;
  }

  .nft-mid {
    width: 32.53vw;
    height: 3.73vw;
    margin-top: 5.33vw;
    font-size: 3.2vw;
    letter-spacing: 1.2px;
  }

  .nft-top-left {
    width: 24vw;
    height: 6.67vw;
  }

  .nft-top-left-icon {
    width: 6.93vw;
    height: 6.67vw;
  }

  .nft-top-left-font {
    width: 12vw;
    height: 5.07vw;
    font-size: 4.27vw;
    letter-spacing: 1.6px;
  }

  .nft-top-right {
    width: 4.8vw;
    height: 4.8vw;
  }

  .body-bottom {
    width: 89.33vw;
    height: 170.67vw;
    margin-top: 7.2vw;
  }

  .nft-top {
    width: 89.33vw;
    height: 6.67vw;
  }



  .wallet-button {
    width: 34.67vw;
    font-size: 3.2vw;
  }

  .wallet-connect-button {
    width: 38.60vw;
    height: 8.21vw;
    margin-top: 2.13vw;
    margin-left: 1.87vw;
  }

  .wallet-bottom-left-left {
    width: 80vw;
    height: 33.6vw;
    margin-left: 4.8vw;
  }

  .wallet-info-title {
    width: 37.33vw;
    height: 11.47vw;
    margin-top: 6.67vw;
    margin-left: 2.4vw;
    font-size: 3.73vw;
  }

  .wallet-bottom-left {
    width: 80vw;
    height: 41.6vw;
    flex-direction: column;
  }

  .wallet-bottom {
    width: 80vw;
    height: 33.6vw;
    margin-top: 5.33vw;
  }


  .wallet-top-left {
    width: 36.27vw;
    height: 5.07vw;
  }

  .wallet-top-left-icon {
    width: 6.13vw;
    height: 5.07vw;
  }


  .wallet-top-left-font {
    width: 36.27vw;
    height: 5.07vw;
    font-size: 4.27vw;
    letter-spacing: 0.8px;
  }

  .wallet-top-right {
    width: 4.8vw;
    height: 4.8vw;
  }


  .body-center {
    width: 89.3vw;
    height: 44vw;
    margin-top: 12vw;
  }

  .wallet-top {
    width: 89.3vw;
    height: 5vw;
  }

  .info-id {
    width: 32vw;
    height: 4.53vw;
    display: flex;
    justify-content: center;
    font-size: 3.46vw;
  }

  .info {
    width: 89vw;
    height: 11.46vw;
    margin-top: 2.4vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .info-name {
    width: 60.8vw;
    height: 10.1vw;
    font-size: 8.5vw;
    text-align: right;
    letter-spacing: 1.6px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10.4vw;
  }

  .info-edit {
    width: 10.4vw;
    height: 5vw;
    font-size: 3.7vw;
    flex: 0;
  }

  .avatar {
    width: 29.3vw;
    height: 29.3vw;
  }

  .top-all {
    width: 89vw;
    align-items: center;
    height: 45vw;
  }

  .body-top {
    margin-top: 8.5vw;
  }
}


.footer {
  width: 100%;
  /* height: 42px; */
  background-color: #080808;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;
}